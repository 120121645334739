"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardRenderItemType = void 0;
var CardRenderItemType;
(function (CardRenderItemType) {
    CardRenderItemType["TEMPERATURE"] = "temperature";
    CardRenderItemType["OXYGEN"] = "oxygen";
    CardRenderItemType["OCEANS"] = "oceans";
    CardRenderItemType["VENUS"] = "venus";
    CardRenderItemType["PLANTS"] = "plants";
    CardRenderItemType["TR"] = "tr";
    CardRenderItemType["HEAT"] = "heat";
    CardRenderItemType["ENERGY"] = "energy";
    CardRenderItemType["TITANIUM"] = "titanium";
    CardRenderItemType["STEEL"] = "steel";
    CardRenderItemType["MEGACREDITS"] = "megacredits";
    CardRenderItemType["CARDS"] = "cards";
    CardRenderItemType["TAG"] = "tag";
    CardRenderItemType["RESOURCE"] = "resource";
    CardRenderItemType["COLONIES"] = "colonies";
    CardRenderItemType["TRADE"] = "trade";
    CardRenderItemType["TRADE_DISCOUNT"] = "trade_discount";
    CardRenderItemType["TRADE_FLEET"] = "trade_fleet";
    CardRenderItemType["COLONY_TILE"] = "colony_tile";
    CardRenderItemType["CHAIRMAN"] = "chairman";
    CardRenderItemType["PARTY_LEADERS"] = "party_leaders";
    CardRenderItemType["DELEGATES"] = "delegates";
    CardRenderItemType["INFLUENCE"] = "influence";
    CardRenderItemType["NO_TAGS"] = "no_tags";
    CardRenderItemType["EMPTY_TAG"] = "empty_tag";
    CardRenderItemType["WILD"] = "wild";
    CardRenderItemType["DIVERSE_TAG"] = "diverse_tag";
    CardRenderItemType["CITY"] = "city";
    CardRenderItemType["GREENERY"] = "greenery";
    CardRenderItemType["PLATE"] = "plate";
    CardRenderItemType["TEXT"] = "text";
    CardRenderItemType["NBSP"] = "nbsp";
    CardRenderItemType["EMPTY_TILE"] = "empty_tile";
    CardRenderItemType["EMPTY_TILE_GOLDEN"] = "empty_tile_golden";
    CardRenderItemType["EMPTY_TILE_SPECIAL"] = "empty_tile_special";
    CardRenderItemType["SELF_REPLICATING"] = "self_replicating";
    CardRenderItemType["MULTIPLIER_WHITE"] = "multiplier_white";
    CardRenderItemType["IGNORE_GLOBAL_REQUIREMENTS"] = "ignore_global_requirements";
    CardRenderItemType["PRELUDE"] = "prelude";
    CardRenderItemType["AWARD"] = "award";
    CardRenderItemType["MILESTONE"] = "milestone";
    CardRenderItemType["CORPORATION"] = "corporation";
    CardRenderItemType["VP"] = "vp";
    CardRenderItemType["COMMUNITY"] = "community";
    CardRenderItemType["RESOURCE_CUBE"] = "resource_cube";
    CardRenderItemType["MOON_HABITAT"] = "moon-colony";
    CardRenderItemType["MOON_HABITAT_RATE"] = "moon-colony-rate";
    CardRenderItemType["MOON_ROAD"] = "moon-road";
    CardRenderItemType["MOON_LOGISTICS_RATE"] = "moon-logistics-rate";
    CardRenderItemType["MOON_MINE"] = "moon-mine";
    CardRenderItemType["MOON_MINING_RATE"] = "moon-mine-rate";
    CardRenderItemType["PLANETARY_TRACK"] = "plantary-track";
    CardRenderItemType["GLOBAL_EVENT"] = "global-event";
    CardRenderItemType["FIRST_PLAYER"] = "1p";
    CardRenderItemType["RULING_PARTY"] = "ruling-party";
    CardRenderItemType["POLICY"] = "policy";
    CardRenderItemType["CATHEDRAL"] = "cathedral";
    CardRenderItemType["NOMADS"] = "nomads";
    CardRenderItemType["CITY_OR_SPECIAL_TILE"] = "city-or-special-tile";
    CardRenderItemType["ARROW_OPG"] = "arrow-opg";
    CardRenderItemType["REDS"] = "reds";
    CardRenderItemType["REDS_DEACTIVATED"] = "reds-deactivated";
    CardRenderItemType["HAZARD_TILE"] = "hazard-tile";
    CardRenderItemType["ADJACENCY_BONUS"] = "adjacency-bonus";
    CardRenderItemType["IDENTIFY"] = "identify";
    CardRenderItemType["EXCAVATE"] = "excavate";
    CardRenderItemType["CORRUPTION"] = "corruption";
    CardRenderItemType["NEUTRAL_DELEGATE"] = "neutral-delegate";
    CardRenderItemType["UNDERGROUND_RESOURCES"] = "underground-resources";
    CardRenderItemType["CORRUPTION_SHIELD"] = "corruptive-block";
    CardRenderItemType["GEOSCAN_ICON"] = "geoscan-icon";
    CardRenderItemType["UNDERGROUND_SHELTERS"] = "underground-shelters";
})(CardRenderItemType = exports.CardRenderItemType || (exports.CardRenderItemType = {}));
